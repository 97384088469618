@media (max-width: 768px){
  .footer.f-fixed{
    margin-left: 0 !important; 
  }
  /*Remove not useful elements from top navigation*/
  .logo, .clock, .lang, .some-btn, .notifications {
    display: none !important;
  }

  /*Remove margins from content block*/
  .content {
    margin: 0;
    padding: 20px 10px 40px;
  }
  
 
  
  /*Project box*/
  .sort-projects .search-project .form-control{
    padding-left: 0; 
    
  }
    
  .project-box{
    width: 100%;
    > div{
      width: 100%; 
      margin-bottom: 10px; 
    }
    .responsible, .mobile-tools{
      display: inline-block;
    }
    .desktop-tools{
      display: none;
    }
  }
  
  .project-item .status-desktop{
    display: none;
  }
  
  .project-item .status-mobile{
    display: block;
  } 
}

@media (max-width: 767px) {
  .cbp_tmtimeline > li .cbp_tmlabel{
    margin-left: 10%;
  }
  .cbp_tmtimeline > li .cbp_tmicon, .cbp_tmtimeline:before{
    left: 5%;
  }
  .cbp_tmtimeline > li .cbp_tmtime{
    display: inline;
    padding-right: 0;
    position: relative;
  }
  
  ul.chat-double:before{
    left: 40px;
  }

  ul.chat-double > li > .chat-panel {
    width: calc(100% - 90px);
  }

  ul.chat-double > li > .chat-badge {
    left: 32px;
    margin-left: 0;
    top: 15px;
  }

  ul.chat-double > li > .chat-panel,.chat ul.chat-double > li.chat-inverted > .chat-panel {
    float: right;
    &:before {
      border-left-width: 0;
      border-right-width: 10px;
      left: -10px;
      right: auto;
    }
    &:after {
      border-left-width: 0;
      border-right-width: 9px;
      left: -9px;
      right: auto;
    }
  }
  .chat-double .more{
    margin-left: 0;
    left: 8px;
  }
  .chat-double .chat-year{
    margin-left: 0;
    left: 12px;
  }
  .chat-double .now{
    margin-left: 0;
    left: 3px;
  }
  .chat-double .chat-panel.blue-bg:after{
    border-left: 0 solid $blue;
    border-right: 9px solid $blue;
  }

  .chat-double .chat-panel.light-blue-bg:after{
    border-left: 0 solid $light-blue;
    border-right: 9px solid $light-blue;
  }

  .chat-double .chat-panel.green-bg:after{
    border-left: 0 solid $green;
    border-right: 9px solid $green;
  }

  .chat-double .chat-panel.red-bg:after{
    border-left: 0 solid $red;
    border-right: 9px solid $red;
  }

  .chat-double .chat-panel.yellow-bg:after{
    border-left: 0 solid $yellow;
    border-right: 9px solid $yellow;
  }
  
  .chat ul.chat-double > li > .chat-panel {
    &:before {
      border-left-width: 10px;
      border-right-width: 0;
      left: auto;
      right: -10px;
    }
    &:after {
      border-left-width: 9px;
      border-right-width: 0;
      left: auto;
      right: -9px;
    }
  }
  
  
  
  .fc-calendar .fc-toolbar{
    padding: 10%;
    .fc-right{
      bottom: 1%; 
      button{
        font-size: 12px; 
      }
    }
    .fc-center h2{
      font-size: 28px; 
    }
  }
  .fc-event-container .fc-event{
    font-size: 10px;  
  }
  
  .btn-breadcrumb .btn{
    background: transparent !important;
    border: none !important;
    color: $text-color !important;
    &:before, &:last-child::after{
      content: none !important; 
    }
    &:after{
      content: " \00bb" !important;
      border: none !important;
      margin-top: -10px !important;
    }
    &:last-child{
      padding: 6px 12px;   
    }
  }
  .btn-breadcrumb .btn.btn-lg{
    &:after{
      margin-top: -13px !important;
    }
    &:last-child{
      padding: 6px 18px !important;   
    }
  }
  
  .email-content, .email-new{
    max-width: 100%;
    width: 100%;
  }
  .table-inbox tr td{
    padding: 4px !important; 
  }
  .inbox-checkbox {
    width: 33px;
  }  
  
  
  .login h1 {
    font-size: 45px;
  } 
  
  .login .login-form {
    padding: 15px;
  }
  
  .login-modal button.close {
    top: 20px;
    z-index: 999;
  }
}

@media(min-width: 992px) and (max-width: 1366px){
  .ultra-widget {
    .w-icon {
      font-size: 90px;	
    }
    .w-name {
	  font-size: 16px;
    }
    .w-amount {
	  font-size: 32px;
    }
    .w-descr {
      top: 10px;
      .w-amount i{
        font-size: 51px !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .pagination li {
    display: inline;
  }
  .pagination.divided li {
    display: inline-block;
  }
}

@media(max-width: 480px){
  .ultra-widget {
    .w-icon {
      font-size: 90px;	
    }
    .w-name {
	  font-size: 16px;
    }
    .w-amount {
	  font-size: 32px;
    }
    .w-descr {
      top: 10px;
      .w-amount i{
        font-size: 51px;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .table-inbox tr td{
    white-space: nowrap !important; 
  }
  .content-tools > a{
    margin-left: 18px;
    display: inline-block;
  }
  .gn-search-item input.gn-search {
    width: 100%;
  }
}



/* chat */
@media screen and (max-width: 65.375em) {
  .cbp_tmchat > li .cbp_tmtime span:last-child {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 47.2em) {
  .cbp_tmchat {
    &:before {
      display: none;
    }
    > li {
      .cbp_tmtime {
        width: 100%;
        position: relative;
        padding: 0 0 20px 0;
        span {
          text-align: left;
        }
      }
      .cbp_tmlabel {
        margin: 0 0 30px 0;
        padding: 1em;
        font-weight: 400;
        font-size: 95%;
        &:after {
          right: auto;
          left: 20px;
          border-right-color: transparent;
          border-bottom-color: #3594cb;
          top: -20px;
        }
      }
      &:nth-child(odd) .cbp_tmlabel:after {
        border-right-color: transparent;
        border-bottom-color: #6cbfee;
      }
      .cbp_tmicon {
        position: relative;
        float: right;
        left: auto;
        margin: -55px 5px 0 0px;
      }
    }
  }
}