.preloader {
  z-index: 99999;
  position: fixed !important;
  height: 100%;
  width: 100%;
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-scale-multiple,
.la-ball-scale-multiple > div {
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.la-ball-scale-multiple {
    display: block;
    font-size: 0;
    color: $blue;
    margin: 0 auto;
}
.la-ball-scale-multiple.la-dark {
    color: #333;
}
.la-ball-scale-multiple > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}
.la-ball-scale-multiple {
    width: 32px;
    height: 32px;
}
.la-ball-scale-multiple > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    opacity: 0;
    animation: ball-scale-multiple 1s 0s linear infinite;
}
.la-ball-scale-multiple > div:nth-child(2) {
    animation-delay: .2s;
}
.la-ball-scale-multiple > div:nth-child(3) {
    animation-delay: .4s;
}
.la-ball-scale-multiple.la-sm {
    width: 16px;
    height: 16px;
}
.la-ball-scale-multiple.la-sm > div {
    width: 16px;
    height: 16px;
}
.la-ball-scale-multiple.la-2x {
    width: 64px;
    height: 64px;
}
.la-ball-scale-multiple.la-2x > div {
    width: 64px;
    height: 64px;
}
.la-ball-scale-multiple.la-3x {
    width: 96px;
    height: 96px;
}
.la-ball-scale-multiple.la-3x > div {
    width: 96px;
    height: 96px;
}
/*
 * Animation
 */
@-webkit-keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@-moz-keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@-o-keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}


/*Timer Loader*/
.refresh-preloader {
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  position: absolute;
  display: none;
  z-index: 100;
  .la-timer {
    position: absolute;
	left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.la-timer,
.la-timer > div {
    position: relative;
    box-sizing: border-box;
}
.la-timer {
    display: block;
    font-size: 0;
    color: #fff;
}
.la-timer.la-dark {
    color: #BBC1C7;
}
.la-timer > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}
.la-timer {
    width: 32px;
    height: 32px;
}
.la-timer > div {
    width: 32px;
    height: 32px;
    background: transparent;
    border-width: 2px;
    border-radius: 100%;
}
.la-timer > div:before,
.la-timer > div:after {
    position: absolute;
    top: 14px;
    left: 14px;
    display: block;
    width: 2px;
    margin-top: -1px;
    margin-left: -1px;
    content: "";
    background: currentColor;
    border-radius: 2px;
    transform-origin: 1px 1px 0;
    animation: timer-loader 1250ms infinite linear;
    animation-delay: -625ms;
}
.la-timer > div:before {
    height: 12px;
}
.la-timer > div:after {
    height: 8px;
    animation-duration: 15s;
    animation-delay: -7.5s;
}
.la-timer.la-sm {
    width: 16px;
    height: 16px;
}
.la-timer.la-sm > div {
    width: 16px;
    height: 16px;
    border-width: 1px;
}
.la-timer.la-sm > div:before,
.la-timer.la-sm > div:after {
    top: 7px;
    left: 7px;
    width: 1px;
    margin-top: -.5px;
    margin-left: -.5px;
    border-radius: 1px;
    transform-origin: .5px .5px 0;
}
.la-timer.la-sm > div:before {
    height: 6px;
}
.la-timer.la-sm > div:after {
    height: 4px;
}
.la-timer.la-2x {
    width: 64px;
    height: 64px;
}
.la-timer.la-2x > div {
    width: 64px;
    height: 64px;
    border-width: 4px;
}
.la-timer.la-2x > div:before,
.la-timer.la-2x > div:after {
    top: 28px;
    left: 28px;
    width: 4px;
    margin-top: -2px;
    margin-left: -2px;
    border-radius: 4px;
    transform-origin: 2px 2px 0;
}
.la-timer.la-2x > div:before {
    height: 24px;
}
.la-timer.la-2x > div:after {
    height: 16px;
}
.la-timer.la-3x {
    width: 96px;
    height: 96px;
}
.la-timer.la-3x > div {
    width: 96px;
    height: 96px;
    border-width: 6px;
}
.la-timer.la-3x > div:before,
.la-timer.la-3x > div:after {
    top: 42px;
    left: 42px;
    width: 6px;
    margin-top: -3px;
    margin-left: -3px;
    border-radius: 6px;
    transform-origin: 3px 3px 0;
}
.la-timer.la-3x > div:before {
    height: 36px;
}
.la-timer.la-3x > div:after {
    height: 24px;
}
/*
 * Animation
 */
@-webkit-keyframes timer-loader {
    0% {
       transform: rotate(0deg);
    }
    100% {
       transform: rotate(360deg);
    }
}
@-moz-keyframes timer-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-o-keyframes timer-loader {
    0% {
       transform: rotate(0deg);
    }
    100% {
       transform: rotate(360deg);
    }
}
@keyframes timer-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}