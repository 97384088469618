//Menu
.gn-scroller {
  height: calc(100% - 65px);
  overflow-x: hidden;
}
.gn-menu-main,
.gn-menu-main ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: none;
  font-weight: 300;
  line-height: 66px;
  z-index: 1200;
  width: 270px;
}

.gn-menu-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  font-size: 13px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  a {
    display: block;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
  }
  > li {
    display: block;
    float: left;
    height: 100%;
    text-align: center;
    > a {
      padding: 0 30px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: bold;
    }
  }
  li.gn-trigger {
    position: relative;
    width: 66px;
    -webkit-touch-callout: none;
    user-select: none;
  }
  &:after {
    display: table;
    clear: both;
    content: "";
  }
  .navbar-right{
    width: auto;
    line-height: 56px;
    a{
      padding: 0 20px !important;
      font-size: 18px;
    }
    .mdi{
      font-size: 30px;
      vertical-align: -5px;
    }
    .cs-select{
      margin-bottom: 0;
      > span{
        padding: 0 3em 0 1em;
        height: 55px;
        line-height: 50px;
        margin-top: -1px;
      }
      ul{
        width: auto;
        line-height: 1.42857143;
        li{
          width: 100%;
          span{
            background-repeat: no-repeat;
            background-position: 12% 50%;
            background-size: 2em auto;
            padding-left: 52px;
            text-align: left;
          }
        }
      }
    }
    .cs-skin-elastic .cs-options li.flag-france span {
        background-image: url(../img/lang/France.png);
    }
    .cs-skin-elastic .cs-options li.flag-england span {
        background-image: url(../img/lang/uk.png);
    }
    .cs-skin-elastic .cs-options li.flag-germany span {
        background-image: url(../img/lang/Germany.png);
    }
    .label{
      right: 8px;
    }
  }
  .logo{
    line-height: 56px;
    font-size: 20px;
    font-weight: 300;
    padding: 0 23px;
    i{
      margin-left: 10px;
    }
  }
  .member-info{
    line-height: 1.42857143;
  }
}



.menu-toggle {
  position:relative;
  cursor:pointer;
  span {
    display:block;
    border-radius:2px;
    transition-duration: .2s;
    transition-property: top, left, width, height;
  }
  .hamburger {
    position:absolute;
    margin-left: -13px;
    top: 12px;
    span {
      width:30px;
      height:3px;
      position:relative;
      margin:6px 0;
      &:nth-child(1){
        transition-delay: .5s;
      }
      &:nth-child(2){
        transition-delay: .625s;
      }
      &:nth-child(3){
        transition-delay: .75s;
      }
    }
  }
  .cross {
    position:absolute;
    height:100%;
    width:100%;
    transform: rotate(45deg);
    span{
      &:nth-child(1){
        height:0%;
        width:3px;
        position:absolute;
        top:35px;
        left:12px;
        transition-delay: 0s;
      }
      &:nth-child(2){
        width:0%;
        height:3px;
        position:absolute;
        left:-3px;
        top:50px;
        transition-delay: .25s;
      }
    }
  }
}

.menu-toggle.gn-selected {
  .hamburger {
    span {
      width:0%;
      &:nth-child(1){
        transition-delay: 0s;
      }
      &:nth-child(2){
        transition-delay: .125s;
      }
      &:nth-child(3){
        transition-delay: .25s;
      }
    }
  }
  .cross {
    span {
      &:nth-child(1){
        height:60%;
        transition-delay: .625s;
      }
    }
    span {
      &:nth-child(2){
        width:52%;
        transition-delay: .375s;
      }
    }
  }
}

.gn-menu-wrapper {
  position: fixed;
  top: 56px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 66px;
  transform: translateX(-66px);
  transition: transform 0.3s, width 0.3s;
}

.gn-menu {
  text-align: left;
  font-size: 16px;
  padding-bottom: 40px !important;
  li.active>a{
    color: $white !important;
  }
  i{
    padding: 0 24px;
    font-size: 19px;
  }
  .label{
    margin-top: 24px;
    margin-right: 12px;
    padding-top: .3em;
  }
  .arrow {
    float: right;
    line-height: 66px;
    margin-right: 12px;
  }
}


.gn-submenu {
  position: relative;
  z-index: 0 !important;
  li {
    overflow: hidden;
    height: 0;
    transition: height, .3s;
    a {
      padding-left: 66px;
      position: relative;
      &:before{
        content: "";
        width: 3px;
        height: 100%;
        position: absolute;
        left: 32px;
      }
      &.active {
        &:after{
          content: "";
          position: absolute;
          left: 35px;
          top: 16px;
          border: 8px solid transparent;
        }
      }
    }
  }
}


.gn-search-item{
  position: relative;
  input.gn-search {
    position: relative;
    padding-left: 66px;
    padding-right: 15px;
    width: 270px;
    outline: none;
    border: none;
    font-weight: 300;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 0;
  }
}

input.gn-search:focus {
  cursor: text;
}

.gn-menu-main a.search-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
}



/* if an icon anchor has a span, hide the span */

.search-icon span{
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

/* styles for opening menu */

.gn-menu-wrapper.gn-open-all,
.gn-menu-wrapper.gn-open-part,
.gn-menu-wrapper.gn-open-fixed, {
  transform: translateX(0px);
}

.gn-menu-wrapper.gn-open-all {
  width: 270px;
  .gn-submenu li {
    height: 50px;
    line-height: 50px;
  }
}

.top-clock{
  line-height: 56px;
  font-size: 24px;
  font-weight: 300;
  width: 120px;
  position: absolute;
  left: 50%;
  margin-left: -60px;
  top: 0;
}

.bottom-bnts {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  a{
    display: block;
    line-height: 66px;
    font-size: 19px;
    text-align: center;
  }
  .profile, .fix-nav {
  display: none;
  }
}

.gn-open-all .bottom-bnts a {
  display: inline-block;
  width: 32%;
}


.mini-nav .content {
  margin: 0 0 0 60px;
}

.right-menu {
  > li{
    float: left;
  }
  a{
    padding: 15px 30px !important;
    margin: 0;
  }
  .label{
    position: absolute;
    top: 8px;
    right: 15px;
    font-size: 72%;
  }
}

.lang{
  a{
    font-size: 15px;
    padding: 17px 15px !important;
  }
  i{
    margin-left: 5px;
  }
}

.some-btn a{
   font-size: 15px;
   padding: 17px 30px !important;
}


@media screen and (max-width: 422px) {
  .gn-menu-main ul {
    width: 100%;
  }
  .gn-menu-wrapper.gn-open-all {
    transform: translateX(0px);
    width: 100%;
    .bottom-bnts a {
      display: inline-block;
      width: 49%;
    }
    .fix-nav {
      display: none !important;
    }
  }
}
