// Font
$font: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;


//Colors
$green: #42b382;
$dark-green: darken($green, 10%);

$red: #E9585B;

$red-orange: #e53935;
$dark-red-orange: #ce3330;

$blue: #63A8EB;

$light-blue: lighten($blue, 10%);
$dark-blue: darken($blue, 10%);

$yellow: #ffc107;
$dark-yellow: #e4ad06;

$indigo: #3f51b5;
$dark-indigo: #324090;

$orange: #ff5722;
$dark-orange: #d94a1d;

$gray: #273238;
$dark-gray: #313131;

$light-gray: #E7EBEC;

$gray-sidebar: #969696;

$grayish-blue: #37474f;

$dark-grayish-blue: #2a363c;
$grayish-blue-text: #78909c;

$white: #fff;

$bg-color: #F1F4F5;

$text-color: #76828E;
$dark-text: #424242;




//Border-radius
$border-radius: 3px; 

