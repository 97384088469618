/*c3.js styles*/
.c3-tooltip td{
  color: $text-color; 
}

.c3-tooltip th{
  background: $yellow; 
}

/*FlotChart.js styles*/
.flot-chart{
  width: 100%;
  height: 276px;
}

.flot-big{
  padding: 0 50px;
  height: 450px;
}

.flot-tooltip{
  color: $white; 
  border-radius: 5px;
  width: 100px;
  height: 30px;
  padding: 5px;
  text-align: center;
  position: absolute;
  display: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}

/*Chart JS*/
.chartjs-container {
  width: 100%;
}

/*Morris JS*/
.morris-container {
  height: 300px;
}

/*EasypieChart.js styles*/
.easypiechart{
  color: $text-color; 
  width: 110px;
  position: relative;
  padding-bottom: 10px;
  margin-right: 5%;
  span{
    position: absolute;
    top: 40px;
    left: 36px;
    font-size: 20px;
  }
}

//Sparkline tooltip
.jqstooltip{
  height: 35px;
  width: 80px;
}


/*Zabuto Calendar styles*/
.calendar{
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  position: relative; 
  .weekday, .month-year{
    font-size: 14px;
    font-weight: 700;
  }
  .date{
    font-size: 72px;
    font-weight: 100;
  }
}

.zabuto_calendar td{
  border: none !important;
  font-weight: 400;
  font-size: 14px;
  .badge-today{
    background-color: $blue; 
    font-size: 14px;
    border-radius: 2px;
  }
}

div.zabuto_calendar .table tr:last-child{
  border-bottom: 0; 
}

div.zabuto_calendar .table tr.calendar-dow-header th{
  background-color: $white; 
  color: $yellow;
  text-transform: uppercase;
  font-weight: 700;
  border-top: 0;
}

div.zabuto_calendar .table tr.calendar-month-header th{
  border: 0;
  background: $white;
}


/*Simple Weather styles*/
.weather-container{
  font-weight: 400;
  font-size: 16px;
  position: relative;
  h4.weather-city{
    padding: 10px;
    color: $white;
    background: $blue;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }
  span.weather-city{
    background: $white;
    padding: 8px 30px;
    color: $text-color;
    border-radius: 20px;
    position: absolute;
    top: 10px;
    right: 30px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2); 
  }
  div{
    border-right: 1px solid #EFEFEF;
    width: 33.3%;
    &:last-child{
      border: 0; 
    }
  }
  i{
    font-size: 36px; 
  }
  &.full{
    div{
      width: 20%; 
      padding: 45px 20px;
    }
  }
}

//SlimScroll
body nav .slimScrollDiv{
  padding-bottom: 65px; 
}

body .slimScrollDiv{
  padding-bottom: 0; 
}

/*Meteocons icons*/
@font-face {
  font-family: 'meteocons-webfont';
  src: url('../fonts/meteocons/meteocons-webfont.eot?86805246');
  src: url('../fonts/meteocons/meteocons-webfont.eot?86805246#iefix') format('embedded-opentype'),
       url('../fonts/meteocons/meteocons-webfont.woff?86805246') format('woff'),
       url('../fonts/meteocons/meteocons-webfont.ttf?86805246') format('truetype'),
       url('../fonts/meteocons/meteocons-webfont.svg?86805246#meteocons-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "meteocons-webfont";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
}

.icon-0:before { content: "\e827"; }
.icon-1:before { content: "\e827"; }
.icon-2:before { content: "\e827"; }
.icon-3:before { content: "\e827"; }
.icon-4:before { content: "\e827"; }
.icon-5:before { content: "\e809"; }
.icon-6:before { content: "\e809"; }
.icon-7:before { content: "\e809"; }
.icon-8:before { content: "\e809"; }
.icon-9:before { content: "\e809"; }
.icon-10:before { content: "\e800"; }
.icon-11:before { content: "\e809"; }
.icon-12:before { content: "\e809"; }
.icon-13:before { content: "\e809"; }
.icon-14:before { content: "\e809"; }
.icon-15:before { content: "\e808"; }
.icon-16:before { content: "\e808"; }
.icon-17:before { content: "\e80a"; }
.icon-18:before { content: "\e809"; }
.icon-19:before { content: "\e822"; }
.icon-20:before { content: "\e822"; }
.icon-21:before { content: "\e822"; }
.icon-22:before { content: "\e822"; }
.icon-23:before { content: "\e822"; }
.icon-24:before { content: "\e82e"; }
.icon-25:before { content: "\e82e"; }
.icon-26:before { content: "\e810"; }
.icon-27:before { content: "\e810"; }
.icon-28:before { content: "\e810"; }
.icon-29:before { content: "\e823"; }
.icon-30:before { content: "\e823"; }
.icon-31:before { content: "\e80e"; }
.icon-32:before { content: "\e803"; }
.icon-33:before { content: "\e80e"; }
.icon-34:before { content: "\e803"; }
.icon-35:before { content: "\e80a"; }
.icon-36:before { content: "\e803"; }
.icon-37:before { content: "\e827"; }
.icon-38:before { content: "\e827"; }
.icon-39:before { content: "\e827"; }
.icon-40:before { content: "\e809"; }
.icon-41:before { content: "\e808"; }
.icon-42:before { content: "\e809"; }
.icon-43:before { content: "\e808"; }
.icon-44:before { content: "\e823"; }
.icon-45:before { content: "\e827"; }
.icon-46:before { content: "\e809"; }
.icon-47:before { content: "\e827"; }
